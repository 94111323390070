class ModalManager {
  #cardRenderer;

  #pyramidPoints;

  #modal;

  #modalClose;

  #modalBackdrop;

  #cardBonusContainer;

  constructor(cardRenderer) {
    this.#cardRenderer = cardRenderer;
    this.#pyramidPoints = document.querySelectorAll('.pyramid__points-item');
    this.#modal = document.querySelector('.modal');
    this.#modalClose = document.querySelector('.modal-content__close');
    this.#modalBackdrop = document.querySelector('.modal__backdrop');
    this.#cardBonusContainer = this.#modal.querySelector('.card-bonus-container');
  }

  init() {
    this.#pyramidPoints.forEach((item) => {
      item.addEventListener('click', () => {
        const pointLevel = item.getAttribute('data-point-level');
        this.openModal(pointLevel);
      });
    });

    this.#modalClose.addEventListener('click', () => {
      this.closeModal();
    });

    this.#modalBackdrop.addEventListener('click', () => {
      this.closeModal();
    });
  }

  openModal(pointLevel) {
    this.#modal.classList.remove('modal--out');
    this.#modal.classList.add('modal--in');
    this.#cardBonusContainer.innerHTML = '';
    this.#cardBonusContainer.setAttribute('data-card-level', pointLevel);
    this.#cardRenderer.renderCards('.modal .card-bonus-container');
  }

  closeModal() {
    this.#modal.classList.remove('modal--in');
    this.#modal.classList.add('modal--out');
  }
}

export default ModalManager;
