const cardsData = [
  {
    level: '1',
    xp: '10 000 XP',
    checkpoint: false,
    cashbackSports: { text: 'Кешбэк спорт', label: '10%', icon: 'check' },
    cashbackCasino: { text: 'Кешбэк казино', icon: 'lock' },
    birthdayGift: { text: 'Подарок на день рождения', icon: 'lock' },
    levelGift: { text: 'Подарок за сохранение уровня', icon: 'lock' },
    tournaments: { text: 'Закрытые турниры', icon: 'lock' },
    cashbackLive: { text: 'Кешбэк лайв', icon: 'lock' },
    personalBonuses: { text: 'Персональные бонусы', icon: 'lock' },
    personalManager: { text: 'Персональный менеджер', icon: 'lock' },
    bonusBets: { text: 'Бонус за сумму ставок', icon: 'lock' },
    maxBets: { text: 'Макс беты в период отыгрыша бонуса', label: '300 RUB' },
    wager: { text: 'Вейджер', label: 'x45' },
    link: '/someone1',
  },
  {
    level: '2',
    xp: '20 000 XP',
    checkpoint: false,
    cashbackSports: { text: 'Кешбэк спорт', label: '10%' },
    cashbackCasino: { text: 'Кешбэк казино', icon: 'check' },
    birthdayGift: { text: 'Подарок на день рождения', icon: 'lock' },
    levelGift: { text: 'Подарок за сохранение уровня', icon: 'lock' },
    tournaments: { text: 'Закрытые турниры', icon: 'lock' },
    cashbackLive: { text: 'Кешбэк лайв', icon: 'lock' },
    personalBonuses: { text: 'Персональные бонусы', icon: 'lock' },
    personalManager: { text: 'Персональный менеджер', icon: 'lock' },
    bonusBets: { text: 'Бонус за сумму ставок', icon: 'lock' },
    maxBets: { text: 'Макс беты в период отыгрыша бонуса', label: '300 RUB' },
    wager: { text: 'Вейджер', label: 'x45' },
    link: '/someone1',
  },
  {
    level: '3',
    xp: '30 000 XP',
    checkpoint: true,
    cashbackSports: { text: 'Кешбэк спорт', label: '2%' },
    cashbackCasino: { text: 'Кешбэк казино', label: '5%' },
    birthdayGift: { text: 'Подарок на день рождения', icon: 'check' },
    levelGift: { text: 'Подарок за сохранение уровня', icon: 'lock' },
    tournaments: { text: 'Закрытые турниры', icon: 'lock' },
    cashbackLive: { text: 'Кешбэк лайв', icon: 'lock' },
    personalBonuses: { text: 'Персональные бонусы', icon: 'lock' },
    personalManager: { text: 'Персональный менеджер', icon: 'lock' },
    bonusBets: { text: 'Бонус за сумму ставок', icon: 'lock' },
    maxBets: { text: 'Макс беты в период отыгрыша бонуса', label: '300 RUB' },
    wager: { text: 'Вейджер', label: 'x45' },
    link: '/someone1',
  },
  {
    level: '4',
    xp: '40 000 XP',
    checkpoint: false,
    cashbackSports: { text: 'Кешбэк спорт', labelUp: '4%' },
    cashbackCasino: { text: 'Кешбэк казино', label: '5%' },
    birthdayGift: 'Подарок на день рождения',
    levelGift: { text: 'Подарок за сохранение уровня', icon: 'lock' },
    tournaments: { text: 'Закрытые турниры', icon: 'lock' },
    cashbackLive: { text: 'Кешбэк лайв', icon: 'lock' },
    personalBonuses: { text: 'Персональные бонусы', icon: 'lock' },
    personalManager: { text: 'Персональный менеджер', icon: 'lock' },
    bonusBets: { text: 'Бонус за сумму ставок', icon: 'lock' },
    maxBets: { text: 'Макс беты в период отыгрыша бонуса', label: '300 RUB' },
    wager: { text: 'Вейджер', label: 'x40' },
    link: '/someone1',
  },
  {
    level: '5',
    xp: '50 000 XP',
    checkpoint: false,
    cashbackSports: { text: 'Кешбэк спорт', label: '4%' },
    cashbackCasino: { text: 'Кешбэк казино', label: '5%' },
    birthdayGift: 'Подарок на день рождения',
    levelGift: { text: 'Подарок за сохранение уровня', icon: 'check' },
    tournaments: { text: 'Закрытые турниры', icon: 'check' },
    cashbackLive: { text: 'Кешбэк лайв', icon: 'lock' },
    personalBonuses: { text: 'Персональные бонусы', icon: 'lock' },
    personalManager: { text: 'Персональный менеджер', icon: 'lock' },
    bonusBets: { text: 'Бонус за сумму ставок', icon: 'lock' },
    maxBets: { text: 'Макс беты в период отыгрыша бонуса', label: '300 RUB' },
    wager: { text: 'Вейджер', label: 'x40' },
    link: '/someone1',
  },
  {
    level: '6',
    xp: '60 000 XP',
    checkpoint: false,
    cashbackSports: { text: 'Кешбэк спорт', label: '4%' },
    cashbackCasino: { text: 'Кешбэк казино', labelUp: '7%' },
    birthdayGift: 'Подарок на день рождения',
    levelGift: 'Подарок за сохранение уровня',
    tournaments: 'Закрытые турниры',
    cashbackLive: { text: 'Кешбэк лайв', icon: 'lock' },
    personalBonuses: { text: 'Персональные бонусы', icon: 'lock' },
    personalManager: { text: 'Персональный менеджер', icon: 'lock' },
    bonusBets: { text: 'Бонус за сумму ставок', icon: 'lock' },
    maxBets: { text: 'Макс беты в период отыгрыша бонуса', label: '300 RUB' },
    wager: { text: 'Вейджер', label: 'x40' },
    link: '/someone1',
  },
  {
    level: '7',
    xp: '70 000 XP',
    checkpoint: false,
    cashbackSports: { text: 'Кешбэк спорт', labelUp: '8%' },
    cashbackCasino: { text: 'Кешбэк казино', label: '7%' },
    birthdayGift: 'Подарок на день рождения',
    levelGift: 'Подарок за сохранение уровня',
    tournaments: 'Закрытые турниры',
    cashbackLive: { text: 'Кешбэк лайв', label: '10%', icon: 'check' },
    personalBonuses: { text: 'Персональные бонусы', icon: 'check' },
    personalManager: { text: 'Персональный менеджер', icon: 'lock' },
    bonusBets: { text: 'Бонус за сумму ставок', icon: 'lock' },
    maxBets: { text: 'Макс беты в период отыгрыша бонуса', label: '300 RUB' },
    wager: { text: 'Вейджер', label: 'x40' },
    link: '/someone1',
  },
  {
    level: '8',
    xp: '80 000 XP',
    checkpoint: false,
    cashbackSports: { text: 'Кешбэк спорт', label: '8%' },
    cashbackCasino: { text: 'Кешбэк казино', labelUp: '10%' },
    birthdayGift: 'Подарок на день рождения',
    levelGift: 'Подарок за сохранение уровня',
    tournaments: 'Закрытые турниры',
    cashbackLive: { text: 'Кешбэк лайв', labelUp: '2%' },
    personalBonuses: 'Персональные бонусы',
    personalManager: { text: 'Персональный менеджер', icon: 'lock' },
    bonusBets: { text: 'Бонус за сумму ставок', icon: 'lock' },
    maxBets: { text: 'Макс беты в период отыгрыша бонуса', label: '300 RUB' },
    wager: { text: 'Вейджер', label: 'x40' },
    link: '/someone1',
  },
  {
    level: '9',
    xp: '90 000 XP',
    checkpoint: false,
    cashbackSports: { text: 'Кешбэк спорт', label: '8%' },
    cashbackCasino: { text: 'Кешбэк казино', labelUp: '10%' },
    birthdayGift: 'Подарок на день рождения',
    levelGift: 'Подарок за сохранение уровня',
    tournaments: 'Закрытые турниры',
    cashbackLive: { text: 'Кешбэк лайв', labelUp: '3%' },
    personalBonuses: 'Персональные бонусы',
    personalManager: { text: 'Персональный менеджер', icon: 'lock' },
    bonusBets: { text: 'Бонус за сумму ставок', icon: 'lock' },
    maxBets: { text: 'Макс беты в период отыгрыша бонуса', label: '300 RUB' },
    wager: { text: 'Вейджер', label: 'x40' },
    link: '/someone1',
  },
  {
    level: '10',
    xp: '100 000 XP',
    checkpoint: true,
    cashbackSports: { text: 'Кешбэк спорт', labelUp: '12%' },
    cashbackCasino: { text: 'Кешбэк казино', label: '10%' },
    birthdayGift: 'Подарок на день рождения',
    levelGift: 'Подарок за сохранение уровня',
    tournaments: 'Закрытые турниры',
    cashbackLive: { text: 'Кешбэк лайв', labelUp: '4%' },
    personalBonuses: 'Персональные бонусы',
    personalManager: { text: 'Персональный менеджер', icon: 'lock' },
    bonusBets: { text: 'Бонус за сумму ставок', icon: 'lock' },
    maxBets: { text: 'Макс беты в период отыгрыша бонуса', label: '700 RUB' },
    wager: { text: 'Вейджер', label: 'x40' },
    link: '/someone1',
  },
  {
    level: '11',
    xp: '110 000 XP',
    checkpoint: false,
    cashbackSports: { text: 'Кешбэк спорт', label: '12%' },
    cashbackCasino: { text: 'Кешбэк казино', labelUp: '12%' },
    birthdayGift: 'Подарок на день рождения',
    levelGift: 'Подарок за сохранение уровня',
    tournaments: 'Закрытые турниры',
    cashbackLive: { text: 'Кешбэк лайв', labelUp: '5%' },
    personalBonuses: 'Персональные бонусы',
    personalManager: { text: 'Персональный менеджер', icon: 'lock' },
    bonusBets: { text: 'Бонус за сумму ставок', icon: 'lock' },
    maxBets: { text: 'Макс беты в период отыгрыша бонуса', label: '700 RUB' },
    wager: { text: 'Вейджер', label: 'x40' },
    link: '/someone1',
  },
  {
    level: '12',
    xp: '120 000 XP',
    checkpoint: false,
    cashbackSports: { text: 'Кешбэк спорт', label: '12%' },
    cashbackCasino: { text: 'Кешбэк казино', labelUp: '12%' },
    birthdayGift: 'Подарок на день рождения',
    levelGift: 'Подарок за сохранение уровня',
    tournaments: 'Закрытые турниры',
    cashbackLive: { text: 'Кешбэк лайв', labelUp: '6%' },
    personalBonuses: 'Персональные бонусы',
    personalManager: { text: 'Персональный менеджер', icon: 'lock' },
    bonusBets: { text: 'Бонус за сумму ставок', icon: 'lock' },
    maxBets: { text: 'Макс беты в период отыгрыша бонуса', label: '1000 RUB' },
    wager: { text: 'Вейджер', label: 'x20' },
    link: '/someone1',
  },
  {
    level: '13',
    xp: '130 000 XP',
    checkpoint: false,
    cashbackSports: { text: 'Кешбэк спорт', label: '20%' },
    cashbackCasino: { text: 'Кешбэк казино', labelUp: '15%' },
    birthdayGift: 'Подарок на день рождения',
    levelGift: 'Подарок за сохранение уровня',
    tournaments: 'Закрытые турниры',
    cashbackLive: { text: 'Кешбэк лайв', labelUp: '7%' },
    personalBonuses: 'Персональные бонусы',
    personalManager: { text: 'Персональный менеджер', icon: 'check' },
    bonusBets: { text: 'Бонус за сумму ставок', icon: 'lock' },
    maxBets: { text: 'Макс беты в период отыгрыша бонуса', label: '1000 RUB' },
    wager: { text: 'Вейджер', label: 'x10-20' },
    link: '/someone1',
  },
  {
    level: '14',
    xp: '140 000 XP',
    checkpoint: true,
    cashbackSports: { text: 'Кешбэк спорт', label: '20%' },
    cashbackCasino: { text: 'Кешбэк казино', label: '15%' },
    birthdayGift: 'Подарок на день рождения',
    levelGift: 'Подарок за сохранение уровня',
    tournaments: 'Закрытые турниры',
    cashbackLive: { text: 'Кешбэк лайв', labelUp: '8%' },
    personalBonuses: 'Персональные бонусы',
    personalManager: 'Персональный менеджер',
    bonusBets: { text: 'Бонус за сумму ставок', label: '5%' },
    maxBets: { text: 'Макс беты в период отыгрыша бонуса', label: '5000 RUB' },
    wager: { text: 'Вейджер', label: 'x5-10' },
    link: '/someone1',
  },
];

export default cardsData;
