import * as THREE from 'three';

class FogAnimation {
  #options;

  #cloudParticles = [];

  #scene = new THREE.Scene();

  #camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 1, 1000);

  #renderer = new THREE.WebGLRenderer({ alpha: true });

  #resizeObserver;

  constructor(options) {
    this.#options = options;
    this.#camera.position.z = 1;
    this.#camera.rotation.set(1.16, -0.12, 0.04);
    this.#scene.add(new THREE.AmbientLight(0x555555));

    const directionalLight = new THREE.DirectionalLight(0xffeedd);
    directionalLight.position.set(0, 0, 1);
    this.#scene.add(directionalLight);

    this.#scene.fog = new THREE.FogExp2(0x8061AE, 0.005);
    this.#renderer.setClearColor(0x000000, 0);
  }

  #debounce(func, delay) {
    let timer;

    return (...args) => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  #loadTexture() {
    const loader = new THREE.TextureLoader();

    loader.load(this.#options.pngTexture, (texture) => {
      const cloudGeo = new THREE.PlaneGeometry(500, 500);
      const cloudMaterial = new THREE.MeshLambertMaterial({
        map: texture,
        transparent: true,
      });

      for (let i = 0; i < 30; i += 1) {
        const cloud = new THREE.Mesh(cloudGeo, cloudMaterial);

        cloud.position.set(Math.random() * 1800 - 600, 400, Math.random() * 100 - 450);
        cloud.rotation.set(1.16, -0.12, Math.random() * 360);
        cloud.material.opacity = 0.5;

        this.#cloudParticles.push(cloud);
        this.#scene.add(cloud);
      }

      this.#animate();
    });
  }

  #animate = () => {
    this.#cloudParticles.forEach((p) => {
      // eslint-disable-next-line no-param-reassign
      p.rotation.z -= 0.00075;
    });

    this.#renderer.render(this.#scene, this.#camera);

    requestAnimationFrame(this.#animate);
  };

  #handleResize = (entries) => {
    entries.forEach((entry) => {
      const { width, height } = entry.contentRect;

      this.#camera.aspect = width / height;
      this.#camera.updateProjectionMatrix();
      this.#renderer.setSize(width, height);
    });
  };

  #resize() {
    const debouncedHandleResize = this.#debounce(this.#handleResize, 100);

    this.#resizeObserver = new ResizeObserver((entries) => debouncedHandleResize(entries));
    this.#resizeObserver.observe(document.querySelector(this.#options.container));
  }

  init() {
    this.#renderer.setSize(window.innerWidth, window.innerHeight);

    document.querySelector(this.#options.container).appendChild(this.#renderer.domElement);

    this.#loadTexture();
    this.#resize();
  }
}

export default FogAnimation;
