import Iconly from 'iconly';
import Togglefy from 'togglefy';
import FogAnimation from './utils/FogAnimation';
import BadgesPos from './utils/BadgesPos';
import SVGRoad from './utils/SVGRoad';
import ModalManager from './utils/ModalManager';
import SwiperManager from './utils/SwiperManager';
import CardRenderer from './utils/CardRenderer';
import cardsData from './data/cardsData';
import './utils/demo';

// Icons sprite
const icons = new Iconly({
  file: './sprite.svg',
  version: '50.0',
  debug: false,
});

// Calculating badges positions
const badgesPos = new BadgesPos();

// Modal
const modalManager = new ModalManager(CardRenderer);

// Carousel
const swiperManager = new SwiperManager();

//
const clampElements = new Togglefy({
  targetSelector: '.clamp-element',
  triggerSelector: '.clamp-button',
  toggleClassName: 'unclamped',
  exclusive: true,
});

// Fog
const fog = new FogAnimation({
  pngTexture: new URL('./assets/images/texture.png', import.meta.url),
  container: '.hero-background',
});

// Progression of levels
const svgRoad = new SVGRoad(Array.from(document.querySelectorAll('.pyramid-progress, .pyramid-decor')));

const app = () => {
  icons.init().then();

  svgRoad.dashoffset(100);
  svgRoad.dasharray(100);

  badgesPos.init();

  modalManager.init();

  clampElements.init();

  fog.init();

  CardRenderer.init(cardsData);

  SwiperManager.createTrigger(
    '(min-width: 1200px)',
    () => swiperManager.initSwiper(),
    () => swiperManager.destroySwiper(),
  );
};

document.addEventListener('DOMContentLoaded', () => app());
