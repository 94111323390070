class SVGRoad {
  #elements;

  #pathLengths;

  constructor(elements) {
    this.#elements = elements;
    this.#pathLengths = elements.map((el) => el.getTotalLength());
  }

  dasharray(strokeDasharray) {
    this.#elements.forEach((el, index) => {
      const dasharrayValues = `${Math.ceil((strokeDasharray / 100) * this.#pathLengths[index])}px`;
      el.style.setProperty('--dasharray', dasharrayValues);
    });
  }

  dashoffset(strokeDashoffset) {
    this.#elements.forEach((el, index) => {
      const dashoffsetValue = `${Math.ceil((strokeDashoffset / 100) * this.#pathLengths[index])}px`;
      el.style.setProperty('--dashoffset', dashoffsetValue);
    });
  }
}

export default SVGRoad;
