const CardRenderer = {
  cardContainers: document.querySelectorAll('.card-bonus-container'),
  cardsData: [],

  renderCard(cardContainer, card) {
    const cardElement = document.createElement('div');

    cardElement.classList.add('card-bonus');

    if (card.checkpoint) {
      cardElement.classList.add('card-bonus--checkpoint');
    }

    cardElement.innerHTML = `
  <div class="card-bonus__body">
    <div class="badge" data-num="${card.level}">
      <svg class="badge__bg svg-icon">
        <use href="${card.checkpoint ? '#i-badge-checkpoint' : '#i-badge'}"></use>
      </svg>
    </div>
    <div class="label-large">${card.xp}</div>

    ${card.checkpoint ? '<div class="card-bonus-checkpoint">Несгораемый уровень</div>' : ''}

    <ul class="bonus-list bonus-list--body">
      ${Object.entries(card).map(([key, value]) => {
    if (key === 'maxBets' || key === 'wager') {
      return '';
    }
    if (typeof value === 'object' && value.text) {
      return `<li class="${value.icon ? value.icon : ''}">
              <span class="text">${value.text}</span> 
              ${value.label ? `<span class="label">${value.label}</span>` : ''}
              ${value.labelUp ? `<span class="label label--up">${value.labelUp}</span>` : ''}
            </li>`;
    } if (typeof value === 'string' && key !== 'level' && key !== 'xp' && key !== 'link') {
      return `<li>${value}</li>`;
    }
    return '';
  }).join('')}
    </ul>
  </div>

  <div class="card-bonus__bottom">
    <ul class="bonus-list bonus-list--bottom">
      <li><span class="text">${card.maxBets.text}</span> <span class="label">${card.maxBets.label}</span></li>
      <li><span class="text">${card.wager.text}</span> <span class="label">${card.wager.label}</span></li>
    </ul>

    <a class="btn-violet btn-violet--sm" href="${card.link}">Кнопка</a>
  </div>
`;

    cardContainer.appendChild(cardElement);
  },

  renderCards(containerSelector) {
    const cardContainers = document.querySelectorAll(containerSelector);
    [...cardContainers].forEach((cardContainer) => {
      const dataLevel = cardContainer.getAttribute('data-card-level');
      this.cardsData.forEach((card) => {
        if (card.level === dataLevel) {
          this.renderCard(cardContainer, card);
        }
      });
    });
  },

  init(data) {
    this.cardsData = data;
    this.renderCards('.swiper .card-bonus-container');
  },
};

export default CardRenderer;
