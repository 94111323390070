class BadgesPos {
  #uphill;

  #items;

  constructor() {
    this.#uphill = document.querySelector('.pyramid__way');
    this.#items = document.querySelectorAll('.pyramid__points-item');
  }

  badgesPos(path, item, index, totalItems) {
    const length = path.getTotalLength();
    const stepLength = length / (totalItems - 1);
    const currentLength = stepLength * index;

    const { x, y } = path.getPointAtLength(currentLength);
    const { width: svgWidth, height: svgHeight } = this.#uphill.getBBox();

    const leftPosition = `${((x / svgWidth) * 100).toFixed(2)}%`;
    const topPosition = `${((y / svgHeight) * 98).toFixed(2)}%`;

    Object.assign(item.style, { left: leftPosition, top: topPosition });
  }

  init() {
    const path = this.#uphill.querySelector('path');
    const totalItems = this.#items.length;

    this.#items.forEach((item, index) => this.badgesPos(path, item, index, totalItems));
  }
}

export default BadgesPos;
