// demo
const selectElement = document.querySelector('select[name="levels"]');

selectElement.addEventListener('change', (event) => {
  const { value: selectedValue } = event.target;

  document.documentElement.style.setProperty('--user-game-level', selectedValue);
});

// demo
const updateCurrentPoint = () => {
  const gameLevel = getComputedStyle(document.documentElement)
    .getPropertyValue('--user-game-level')
    .trim();

  document.querySelectorAll('.pyramid__points-item[data-current]').forEach((item) => {
    item.removeAttribute('data-current');
  });

  const currentItem = document.querySelector(`.pyramid__points-item[data-point-level="${gameLevel}"]`);

  if (currentItem) {
    currentItem.setAttribute('data-current', '');
  }
};

const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
      updateCurrentPoint();
    }
  });
});

observer.observe(document.documentElement, {
  attributes: true,
  attributeFilter: ['style'],
});

updateCurrentPoint();

// demo
document.addEventListener('DOMContentLoaded', function() {
  document.documentElement.classList.add('demo-hero-unauthorised');

  const radios = document.querySelectorAll('input[name="demoRadio"]');

  radios.forEach((radio) => {
    radio.addEventListener('change', function() {
      if (this.value === 'authorised' || this === radios[1]) {
        document.documentElement.classList.remove('demo-hero-unauthorised');
        document.documentElement.classList.add('demo-hero-authorised');
      } else {
        document.documentElement.classList.add('demo-hero-unauthorised');
        document.documentElement.classList.remove('demo-hero-authorised');
      }
    });
  });
});
