import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import MediaTrigger from 'media-trigger';

class SwiperManager {
  #swiper = null;

  #layoutEdge = 0;

  constructor() {
    this.#calculateLayoutEdge();
  }

  #debounce(func, delay) {
    let timer;

    return (...args) => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  #calculateLayoutEdge = () => {
    const widthThreshold = 1480;
    const currentWidth = window.innerWidth;

    if (currentWidth > widthThreshold) {
      const remainder = currentWidth - widthThreshold;

      this.#layoutEdge = remainder / 2;
    } else {
      this.#layoutEdge = 0;
    }

    if (this.#swiper) {
      this.#swiper.params.slidesOffsetAfter = this.#layoutEdge;
      this.#swiper.params.slidesOffsetBefore = this.#layoutEdge;
      this.#swiper.update();
    }
  };

  initSwiper() {
    window.addEventListener('resize', this.#debounce(this.#calculateLayoutEdge, 200));

    if (!this.#swiper) {
      this.#swiper = new Swiper('.swiper', {
        modules: [Navigation],
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        roundLengths: true,
        spaceBetween: 0,
        slidesPerView: 'auto',
        slidesOffsetAfter: this.#layoutEdge,
        slidesOffsetBefore: this.#layoutEdge,
      });
    }
  }

  destroySwiper() {
    if (this.#swiper) {
      this.#swiper.destroy(true, true);
      this.#swiper = null;
    }
  }

  static createTrigger(media, entry, exit) {
    return new MediaTrigger({
      media,
      entry: () => {
        entry();
      },
      exit: () => {
        exit();
      },
    });
  }
}

export default SwiperManager;
